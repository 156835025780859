import { createContainer } from 'unstated-next'
import { useEffect } from 'react'
import ScrollSaveContainer from '@/containers/ScrollSaveContainer'

/**
 * 저장된 스크롤 데이터를 복원하는 함수 입니다.
 * @param allCase
 */
const useRestoreScroll = (allCase: boolean | undefined = false) => {
  const { handleRestoreScroll } = ScrollSaveContainer.useContainer()

  useEffect(() => {
    handleRestoreScroll(allCase)
  }, [])
}

const ScrollRestoreContainer = createContainer(useRestoreScroll)

export default ScrollRestoreContainer

import classNames from 'classnames'

type BadgeProps = {
  className?: string
  content: string | number
}

const Badge = ({ className, content }: BadgeProps) => {
  return (
    <div
      className={classNames(
        'caption flex min-h-20 min-w-20 items-center justify-center rounded-full font-medium',
        className
      )}>
      {content}
    </div>
  )
}

export default Badge

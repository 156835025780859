import { useEffect, useMemo, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useNodesList } from '@/api/generated/hooks'
import { NodeRes, SiteRes } from '@/api/generated/types'
import { SiteMapBottomSheetDataType } from '@/components/home/SiteMapBottomSheet'
import useBottomSheetLegacy from '@/hooks/common/useBottomSheetLegacy'
import useDropDown from '@/hooks/common/useDropDown'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import GeoMapContainer, { MapEventEnum, MarkerEnum, MarkerStyle } from '@/containers/common/GeoMapContainer'

const usePromiseNode = ({ site }: { site?: SiteRes }) => {
  const siteMapBottomSheetControls = useBottomSheetLegacy<SiteMapBottomSheetDataType>()
  const siteNodeDropDownControls = useDropDown()
  const { geoMap, isGeoMapLoaded, drawMarker, removeMarker, panTo, initGeoMap, setMapSite, mapSite } =
    GeoMapContainer.useContainer()

  const prevSelectedDestinationMarkerRef = useRef<NodeRes>()
  const { data: nodeListOri, isFetching: isNodeListFetching } = useNodesList({
    query: {
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000
    }
  })
  const nodeList = useMemo(() => {
    if (!nodeListOri) {
      return undefined
    }
    const toStringForCompare = (name: string): string => {
      const indexOfComma = name.indexOf(',')
      const indexOfRange = name.indexOf('~')

      let result = name
      if (indexOfComma === -1 && indexOfRange === -1) {
        result = name
      } else if (indexOfComma !== -1 && indexOfRange !== -1) {
        const index = indexOfComma < indexOfRange ? indexOfComma : indexOfRange
        result = name.slice(0, index)
      } else if (indexOfComma !== -1) {
        result = name.slice(0, indexOfComma)
      } else {
        result = name.slice(0, indexOfRange)
      }

      return result.padStart(4, '0')
    }
    return nodeListOri?.sort((a, b) => {
      const resultA = toStringForCompare(a.name)
      const resultB = toStringForCompare(b.name)
      if (resultA === resultB) {
        return 0
      }
      return resultA > resultB ? 1 : -1
    })
  }, [nodeListOri])

  const isCanUseMap = geoMap && isGeoMapLoaded && nodeList && mapSite

  const handleSiteMapOpen = () => {
    siteMapBottomSheetControls.setMountAfterCallbackRef(() => {
      if (!site?.centerLatitude || !site?.centerLongitude || !nodeList) {
        return
      }
      initGeoMap({ longitude: site.centerLongitude, latitude: site.centerLatitude, mapTypeControl: false })
      siteMapBottomSheetControls.setBottomSheetData((prev) => ({ ...prev, site, nodeList }))
      const optionList = nodeList.map((node) => ({ id: node.nodeNumber, content: node.name }))
      siteNodeDropDownControls.setOptionList(optionList)
      const promiseNodeNumber = LocalStorage.getItem<string>(LocalStorageKeyEnum.PromiseNodeNumber)
      if (promiseNodeNumber) {
        const targetOption = optionList.find((option) => option.id === promiseNodeNumber)
        targetOption && siteNodeDropDownControls.setSelectedItem(targetOption)
      }
    })
    siteMapBottomSheetControls.handleOpen()
  }

  /**
   * 1. 선택되지 않은 노드들만 일반 목적지 마커로 그립니다.
   * 2. 선택된 노드만 활성화 목적지 마커로 그립니다.
   */
  const drawDestinationMarker = (node: NodeRes, isActive: boolean) => {
    if (!isCanUseMap) return
    drawMarker({
      zIndex: 0,
      longitude: node.longitude,
      latitude: node.latitude,
      markerType: isActive ? MarkerEnum.SelectedNode : MarkerEnum.NotSelectedNode,
      markerMarkup: isActive
        ? MarkerStyle[MarkerEnum.SelectedNode](node.name)
        : MarkerStyle[MarkerEnum.NotSelectedNode](node.name),
      mapEventType: MapEventEnum.ClickSelectNode,
      onClickListener: () => {
        panTo({ longitude: node.longitude, latitude: node.latitude })
        const optionList = nodeList.map((node) => ({ id: node.nodeNumber, content: node.name }))
        const targetOption = optionList.find((option) => option.id === node.nodeNumber)
        siteNodeDropDownControls.setSelectedItem(targetOption)
      }
    })
  }

  useEffect(() => {
    if (!site) return
    setMapSite(site)
  }, [site])

  useEffect(() => {
    if (!isCanUseMap) return
    nodeList.forEach((node) => drawDestinationMarker(node, false))

    return () => {
      removeMarker({ removeMarkerTypes: [MarkerEnum.NotSelectedNode] })
    }
  }, [geoMap, isGeoMapLoaded, mapSite])

  useEffect(() => {
    if (!isCanUseMap) return
    // 이전 선택된 활성화 마커를 지우고 일반 마커로 그립니다.
    if (prevSelectedDestinationMarkerRef.current) {
      const prevSelectedNode = prevSelectedDestinationMarkerRef.current
      removeMarker({ removeMarkerTypes: [MarkerEnum.SelectedNode] })
      drawDestinationMarker(prevSelectedNode, false)
    }

    if (isEmpty(siteNodeDropDownControls.selectedItem)) return
    const selectedNode = nodeList.find((node) => node.nodeNumber === siteNodeDropDownControls.selectedItem?.id)
    if (isEmpty(selectedNode)) return
    // 선택된 노드는 Destination 마커로 그려졌었기 때문에 기존마커를 지우고 SelectedNode 마커로 다시 그립니다.
    removeMarker({
      removeMarkerTypes: [MarkerEnum.NotSelectedNode],
      removeMarkerCoordinate: { longitude: selectedNode.longitude, latitude: selectedNode.latitude }
    })
    drawDestinationMarker(selectedNode, true)
    panTo({ longitude: selectedNode.longitude, latitude: selectedNode.latitude })
    return () => {
      prevSelectedDestinationMarkerRef.current = selectedNode
    }
  }, [geoMap, isGeoMapLoaded, mapSite, siteNodeDropDownControls.selectedItem])

  // 약속 장소 설정 (promise node)
  const [promiseNodeName, setPromiseNodeName] = useState<string>()
  const setPromiseNode = ({ nodeId, onClose }: { nodeId?: string; onClose?: () => void } = {}) => {
    if (nodeId) {
      LocalStorage.setItem(LocalStorageKeyEnum.PromiseNodeNumber, nodeId)
      const nodeName = nodeList?.find((node) => node.nodeNumber === nodeId)?.name
      nodeName && setPromiseNodeName(nodeName)
    } else {
      const storageNodeName = LocalStorage.getItem<string>(LocalStorageKeyEnum.PromiseNodeNumber)
      const existingNodeName = nodeList?.find((node) => storageNodeName === node.nodeNumber)?.name
      existingNodeName && setPromiseNodeName(existingNodeName)
    }
    onClose?.()
  }
  useEffect(() => {
    if (!nodeList) return
    setPromiseNode()
  }, [nodeList])

  return {
    siteMapBottomSheetControls,
    siteNodeDropDownControls,
    handleSiteMapOpen,
    nodeList,
    promiseNodeName,
    setPromiseNode,
    isNodeListFetching
  }
}

export default usePromiseNode

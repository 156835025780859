import classNames from 'classnames'

type DividerProps = {
  className?: string
}
const Divider = ({ className }: DividerProps) => {
  return <div className={classNames('w-full bg-gray-50', className ?? 'h-1')}></div>
}

export default Divider

import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { AnimationControls, useAnimation } from 'framer-motion'

export type BottomSheetControlsType<T = any> = {
  handleOpen(): void
  handleClose(): void
  mount: boolean
  bottomSheetData: T
  setBottomSheetData: Dispatch<SetStateAction<T>>
  controls: AnimationControls
  setMountAfterCallbackRef: (callback: () => void) => void
}

const useBottomSheet = <T = unknown>(): BottomSheetControlsType<T> => {
  const [mount, setMount] = useState(false)
  const [bottomSheetData, setBottomSheetData] = useState(<T>{})
  const controls = useAnimation()
  const mountAfterCallbackRef = useRef<() => void>()

  const handleOpen = () => {
    // mount
    setMount(true)
    // open animation
    setTimeout(() => {
      controls.start('visible')
      mountAfterCallbackRef.current?.()
    }, 100)
  }

  const handleClose = () => {
    // close animation 시작
    controls.start('hidden')

    // unmount
    setTimeout(() => {
      setBottomSheetData(<T>{})
      mountAfterCallbackRef.current = undefined
      setMount(false)
    }, 300)
  }

  const setMountAfterCallbackRef = (callback: () => void) => {
    mountAfterCallbackRef.current = callback
  }

  return {
    handleOpen,
    handleClose,
    mount,
    bottomSheetData,
    setBottomSheetData,
    controls,
    setMountAfterCallbackRef
  }
}

export default useBottomSheet

import { ReactNode, useEffect, useRef, useState } from 'react'

type ToastDataType = {
  id: string
  content: ReactNode
}

export type ToastControlsType = {
  toastListData?: ToastDataType[]
  addToast(content: ReactNode): void
}

const useToast = (): ToastControlsType => {
  const [toastListData, setToastListData] = useState<ToastDataType[]>()
  const isAdd = useRef(false)
  const toastId = String(new Date().getTime())

  useEffect(() => {
    if (!isAdd.current) {
      return
    }
    setTimeout(() => {
      setToastListData((prev) => {
        if (prev) {
          const result = [...prev]
          result.shift()
          return result
        }
        return undefined
      })
    }, 3000)
    isAdd.current = false
  }, [toastListData])

  const addToast = (content: ReactNode) => {
    isAdd.current = true
    const toastData = { id: toastId, content }
    setToastListData((prev) => {
      if (prev) {
        return [...prev, toastData]
      }
      return [toastData]
    })
  }

  return { toastListData, addToast }
}

export default useToast

import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'
import Icon from '@/components/common/Icon'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import DropDown, { searchTextHighLight } from '@/components/common/design-system/DropDown'
import { DropDownPropsType } from '@/hooks/common/useDropDown'
import { NodeRes, SiteRes } from '@/api/generated/types'
import CTAButton from '@/components/common/design-system/CTAButton'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import GeoMap from '@/components/geo-map/GeoMap'

export type SiteMapBottomSheetDataType = {
  site?: SiteRes
  nodeList?: NodeRes[]
}

type SiteMapBottomSheetProps = {
  bottomSheetControls: BottomSheetLegacyControlsType<SiteMapBottomSheetDataType>
  dropDownControls: DropDownPropsType
  handleSavePromiseNode: () => void
  nodeList?: NodeRes[]
}

const extractAddress = (nodeList?: NodeRes[], targetID?: string) => {
  return nodeList?.find((node) => node.nodeNumber === targetID ?? '')?.address
}

const SiteMapBottomSheet = (props: SiteMapBottomSheetProps) => {
  return (
    <BottomSheetLegacy bottomSheetControls={props.bottomSheetControls} height="h-[80vh]">
      <SiteMapBottomSheetContent {...props} />
    </BottomSheetLegacy>
  )
}

const SiteMapBottomSheetContent = ({
  bottomSheetControls,
  dropDownControls,
  handleSavePromiseNode,
  nodeList
}: SiteMapBottomSheetProps) => {
  const extractedAddress = extractAddress(nodeList, dropDownControls.selectedItem?.id)

  return (
    <>
      <BottomSheetLegacy.Header bottomSheetControls={bottomSheetControls} isShowCloseButton>
        약속 장소 설정
      </BottomSheetLegacy.Header>
      <div className="flex h-28 w-full items-center justify-center gap-4 text-warning">
        <Icon className="h-20 w-20" name={IconNamesEnum.Information} />
        <span className="body3 font-medium">뉴비는 정해진 장소에서만 만날 수 있어요</span>
      </div>
      <GeoMap className="mt-8 h-full w-full bg-gray-300" />
      <div className="w-full py-16 px-16">
        <div className="flex items-center gap-2">
          <Icon name={IconNamesEnum.LocationFilledFalse} className="h-20 w-20" />
          <span className="body2">
            {dropDownControls.selectedItem?.content ?? bottomSheetControls.bottomSheetData.site?.name}
          </span>
        </div>
        {/*todo: margin-left의 값을 w-22로 하드코딩 하지 않는 방법으로 개선*/}
        {extractedAddress && <p className="body3 ml-22 text-gray-500">{extractedAddress}</p>}
        <div className="h-17 w-full" />
        <DropDown defaultItemText="약속 장소 설정" dropDownProps={dropDownControls}>
          <DropDown.Search searchPlaceHolder="건물명을 입력하세요." dropDownProps={dropDownControls} />
          <DropDown.List>
            {dropDownControls.searchFilterList?.map((item) => {
              return (
                <DropDown.Item
                  dropDownProps={dropDownControls}
                  key={item.id}
                  itemContent={{
                    id: item.id,
                    content: <span>{searchTextHighLight(item.content, dropDownControls.searchText)}</span>
                  }}
                />
              )
            })}
          </DropDown.List>
        </DropDown>
      </div>
      <div className="h-16 w-full" />
      <CTAButton>
        <Button
          full
          theme={ButtonThemeEnum.Primary}
          size={ButtonSizeEnum.ExtraLarge}
          disabled={!dropDownControls.selectedItem}
          onClick={handleSavePromiseNode}>
          <span className="body1 font-bold">선택한 위치로 설정</span>
        </Button>
      </CTAButton>
    </>
  )
}

export default SiteMapBottomSheet

import classNames from 'classnames'
import { ReactNode } from 'react'
import SkeletonBase from '@/components/common/skeleton/SkeletonBase'
import BaseHeader, { HeaderTypeHeightEnum } from '@/components/layouts/BaseHeader'

type HeaderProps = {
  leftSide?: ReactNode
  center?: ReactNode
  rightSide?: ReactNode
  showBottomLine?: boolean
}

/**
 * 디자인 시스템을 따르는 헤더 입니다.
 * 3가지 props를 받으며 모두 reactNode로 줍니다.
 *
 * @link: https://www.figma.com/file/bnWIiHfgHgV5QyiVP5ek8t/Design-System?node-id=86-612&t=6kw3ARvFp25quxV3-0
 * @param leftSide: left 사이드에 들어갈 children 입니다.
 * @param center: 타이틀 입니다. 여기에 쓰일 tag는 seo 에서는 h1이여야 합니다.
 * @param rightSide: right 사이드에 들어갈 children 입니다.응
 * @param showBottomLine: border bottom 노출 여부
 */
const Header = ({ leftSide, center, rightSide, showBottomLine = true }: HeaderProps) => {
  return (
    <header
      className={classNames(
        'flex h-57 w-full place-content-between items-end gap-16 bg-white p-16 text-gray',
        showBottomLine && 'border-b-1 border-b-gray-100'
      )}>
      <div className="flex h-24 min-w-64 items-center justify-start">{leftSide}</div>
      <div className="body1 flex flex-1 items-center justify-center truncate text-center font-bold text-gray">
        {center}
      </div>
      <div className="flex h-24 min-w-64 items-center justify-end">{rightSide}</div>
    </header>
  )
}

export default Header

export const SkeletonHeader = () => {
  return (
    <BaseHeader blankDivHeight={HeaderTypeHeightEnum.Header}>
      <Header center={<SkeletonHeaderCenter />}></Header>
    </BaseHeader>
  )
}

export const SkeletonHeaderCenter = () => {
  return <SkeletonBase className="h-25 w-200 rounded-xss" />
}
